.wholePage3 {
  position: absolute;
    top: 0;
    max-width: 100%;
    background-position: 40%;
    background-color: #825344;
}

.mediaBanner {
  background-image: url("../images/juniper8.jpeg");
  background-size: cover;
  background-position: 40% 0%;
  padding-bottom: 200px;
  padding-top: 400px;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.directions4 {
  background: #bd8d7d;
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width:700px) {
  .mediaBanner {
    background-position: 46.5%;
  }
}

@media only screen and (max-width:1230px) {
  .vid {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    width: 320px;
    height: 190px;
  }

  .scrollArea {
    height: 215px;
  }
}