.juniperMain {
    max-width: fit-content;
}

.juniperWhole {
    position: absolute;
    top: 0;
    max-width: 100%;
    background-position: 40%;
    background-color: #825344;
}

.juniperHome2 {
    background-image: url("../images/juniper10.jpeg");
    background-size: cover;
    background-position: 50%;
    padding-top: 300px;
    padding-bottom: 100px;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.juniperTitle2 {
    color: #fcefd9;
    padding: 10px;
    font-size: xx-large;
    font-weight: 900;
    margin-top: 100px;
    margin-bottom: 250px;
    font-family: 'Aboreto', system-ui;

}

.aboutJunBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background:  #fcefd9;
    margin: 2%
}

.lastJunBox {
    padding: 30px;
    background: #bd8d7d;
    border-top: 5px solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 700;
}

.aboutJun {
    max-width: 45%;
    padding: 5%;
    text-align: center;
}

.aboutJunPic {
    max-width: 45%;
}

@media only screen and (max-width:825px) {
    .teachBox {
        flex-wrap: wrap;
        margin-left: 5%;
        margin-right: 5%;
        text-align: center;
    }
    .aboutJun {
      max-width: 90%;
      padding: 5%

    }
  
    .aboutJunPic {
      max-width: 100%;
    }

    .aboutJunBox {
        margin: 3%
    }
  }
  
  .directions3 {
    border-bottom: 5px solid black;
    background: #bd8d7d;
    padding: 15px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }



.videoBackground {
    background-image: url("../images/juniperr2.jpeg");
    background-size: cover;
    background-position: 60%;
    padding-top: 30px;
    padding-bottom: 280px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width:825px) {
    .videoBackground {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 50px;
        background-position: 30%;
    }
}

.iframeJun {
    border: 8px solid #fcefd9;
}

.emailButton {
    border-radius: 20;
    background-color: black;
    color: white;
    font-weight: 900;
    padding: 20px;
}

a {
    color: white;
    font-weight: 900;
    text-decoration: none;
  }

.textJunBox {
    margin: 20px;
    max-width: 500px;
    text-align: center;
    background: #fcefd9;
}

.bottomJunBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.bottomJunBox2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fcefd9;
    ;
    padding-bottom: 15px;
}



.vid {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 20px;
    width: 560px;
    height: 315px;
  }
  
  
  .scrollVids1 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 3px;
    background: #fcefd9;
  }
  
  .scrollArea1 {
    height: 360px
  }
  
  @media only screen and (max-width:1230px) {
    .vid {
      margin-left: 5px;
      margin-right: 5px;
      margin-top: 10px;
      margin-bottom: 10px;
      border-radius: 10px;
      width: 320px;
      height: 190px;
    }
  
    .scrollArea1 {
      height: 215px;
    }
  }

  @media only screen and (min-width:1040px) {
    .bottomJunBox {
        justify-content: space-evenly;
    }

  }