/* .navbar {
    height: fit-content;
    background-color: grey;
    color: black;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
    width: 100%;
    background-image:
      linear-gradient(
        grey, white 100%
      );
} */

.navbar {
    position: relative;
    z-index: 2;
    height: fit-content;
    color: black;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
    width: 100%;
  } 

.logoTitle {
    font-family: 'Italiana', sans-serif;
    font-size: 70px;
}

.cellist {
    font-size: 30px;
}

.navbar__item {
    flex-basis: 20%;
    list-style-type: none;
}

.logoTitleLink {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 20px;
    margin-left: 20px;
}

.navTitle {
    color: black;
    font-size: xx-large;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.navDropDown {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.navRightSection {
    background-color: #ebcec3;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.avatar {
    margin-right: 10px;
}

.messageButton {
    margin-right: 10px;
}

.modalTitle {
    font-size: large;
    font-weight: 700;
    text-align: center;
}

.name {
    font-size: xx-large;
}

.cellistTitle {
    margin-left: 10px;
}



.titleLink {
    text-decoration: none;
    color: #fcefd9;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: left;
    max-height: 100px;
}

.whiteLine {
    height: 3px;
    width: fit-content;
    background-color: #fcefd9;
    color: black;
    margin-bottom: 10px;
}

.titleSection {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    max-height: 100px;
}

.withIcon {
    display: flex;
    flex-direction: row;
    font-size: smaller;
    align-items: center;
}

.phone {
    margin-left: 10px;
    font-weight: 600;
}

.email {
    margin-left: 10px;
}

.navDropDown {
    margin-right: 10px;
}

.withIcon a {
    color: black !important;
    padding-left: 10px;
}

.mantine-1g6tz01 {
    background-color: transparent !important;
    color: #fcefd9 !important; 
}

@media only screen and (max-width:600px) {
    .logoTitle {
        font-size: 40px;
    }
}

.mantine-152fpb {
    background-color: #bd8d7d !important;
}

.mantine-1x9tgy1[data-hovered] {
    background-color: #fcefd9;
}