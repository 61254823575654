

.homeWhole {
    position: absolute;
    top: 0;
    max-width: 100%;
    background-position: 40%;
    background-color: #fcefd9;
  }

.homeBackground {
    background-image: url("../images/NCMS.jpg");
    background-size: cover;
    background-position: 0%;
    padding-top: 100px;
    padding-bottom: 100px;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 20px solid black;
}

@media only screen and (max-width:1000px) {
    .homeBackground {
        background-position: 65%;
        padding-top: 300px;
        padding-bottom: 150px;
    }
}

.nameTitle {
    color: #fcefd9;
    padding: 10px;
    font-size: x-large;
    font-weight: 700;
    font-style: italic;
    margin-top: 400px;
}

.aboutImage {
    background-image: url("../images/about.jpeg");
    max-height: fit-content;
}

.aboutSection0 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}



.aboutText {
    font-size: medium;
    max-width: 47%;
    padding: 4%;
    text-align: center;

}

.sectionTitle {
    font-size: xx-large;
    font-weight: 900;
    padding: 10px;
    text-align: center;
    font-family: 'Aboreto', system-ui;
}

.imageBox {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 45%;
    height:auto;
}

@media only screen and (max-width:750px) {
    .aboutSection0 {
        flex-wrap: wrap;
        text-align: center;
    }
    .imageBox {
      padding-top: 30px;
    }
    .aboutText {
      max-width: 90%;
      padding: 5%;
      font-size:small;
    } 
  }

  @media only screen and (max-width:930px) {
    .aboutText {
      font-size:small;
    }
  }
  

.teachingSection {
    background-image: url("../images/teaching.jpg");
    background-size: cover;
    background-position: 0%;
    padding-top: 75px;
    padding-bottom: 75px;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: left;
    border-top: 20px solid #825344;
    border-bottom: 7px solid black;
}

@media only screen and (max-width:1000px) {
    .teachingSection {
        background-position: 55%;
    }
}

.teachingQuote1 {
    text-align: center;
    width: 300px;
    padding: 20px;
    background: rgba(189, 141, 125, 0.3);
    margin: 10px;
    border-radius: 10px;
    font-family: 'Figtree', sans-serif;

}

.teachingQuote2 {
    text-align: center;
    width: 300px;
    padding: 20px;
    background: rgba(189, 141, 125, 0.7);
    margin: 10px;
    border-radius: 10px;
    font-family: 'Figtree', sans-serif;

}

.teachingQuote3 {
    text-align: center;
    width: 300px;
    padding: 20px;
    background: rgba(189, 141, 125, 0.3);
    margin: 10px;
    border-radius: 10px;
    font-family: 'Figtree', sans-serif;

}

.teachingQuote4 {
    text-align: center;
    width: 300px;
    padding: 20px;
    background: rgba(189, 141, 125, 0.7);
    margin: 10px;
    margin-bottom: 30px;
    border-radius: 10px;
    font-family: 'Figtree', sans-serif;

}

.quotes {
    padding-top: 20px;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: large;
    font-weight: 800;
    font-style: italic;
}

.aboutCard {
    margin-top: 10px;
    margin-bottom: 10px;
    background: #825344;
}

.images {
    width: fit-content;
}

.scrollPics {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.juniperSection {
    max-width: fit-content;
}

.juniperTitle {
    font-size: xx-large;
    font-weight: 900;
    background: #825344;
    padding: 10px;
    text-align: center;
    border-top: 10px solid black;
    margin-top: 30px;
    text-decoration: none;
    color: black;
    font-family: 'Aboreto', system-ui;
}

.juniperTitleLink {
    text-decoration: none;
}

.macBox {
    background-image: url("../images/mac1.jpg");
    background-size: cover;
    background-position: 0%;
    padding-top: 75px;
    padding-bottom: 75px;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: left;
    border-top: 20px solid #825344;
}

.logo {
    padding: 30px;
}

.macInnerBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bottomSection {
    background-color: #825344;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.instaIcon {
    color: black;
    padding: 6px;
    margin: 8px;
    text-align: center;
    align-items: center;
    background-color: #fcefd9;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: fit-content;
}
.instaIcon:hover {
    background-color: #825344;
}

.iconBox {
    display: flex;
}

.mantine-dv0i8e {
    background-color: #fcefd9;
}

.mantine-1e3vyn {
    color:#fcefd9;
}

.mantine-b4c3o {
    background-color: #825344;
   
}