.ncmsPic {
  max-width: 45%;
}

.wholePage2 {
  position: absolute;
  top: 0;
  max-width: 100%;
  background-position: 40%;
  background-color: #825344;
  padding-top: 100px;
}

.teachBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 50px;
  background-color: #fcefd9;;
  
}

.teachText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 45%;
  padding: 5%;
}

.mantine-bmqyhy {
  background: #bd8d7d;

}


@media only screen and (max-width:1070px) {
  .teachBox {
      flex-wrap: wrap;
      margin-left: 5%;
      margin-right: 5%;
      text-align: center;
  }
  .ncmsPic {
    max-width: 100%;
  }

  .teachText {
    max-width: 90%;
    padding: 5%
  }
}
