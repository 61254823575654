.macBanner {
    background-image: url("../images/mac4.jpeg");
    background-size: cover;
    background-position: 50%;
    padding-top: 100px;
    padding-bottom: 400px;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width:1000px) {
    .macBanner {
        background-position: 50%;
    }
}

.titleBanner {
    font-size: x-large;
    font-weight: 900;
    background: #bd8d7d;
    border-top: 5px solid black;
    color: black;
    text-align: center;
    padding: 20px;
    font-family: 'Aboreto', system-ui;


}

.wholePage {
    position: absolute;
    top: 0;
    max-width: 100%;
    background-position: 40%;
    background-color: #825344;}

.aboutBox {
    display: flex;
    flex-direction: row;
    padding: 20px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.textMac {
    max-width: 600px;
    text-align: center;
    padding: 20px;
}

.imageMac {
    max-width: 350px;
    padding: 20px;
}

.aboutTitle {
    text-align: center;
    font-weight: 700;
    font-size: large;
    font-family: 'Aboreto', system-ui;

}


.inventory2 {
    background-color: black;
    background-size: cover;
    background-position: 0%;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.directions {
    background: #bd8d7d;
    padding: 20px;
    margin-bottom: 20px;
    text-align: center;
    border-top: 8px dotted black;
}

.smallerDirections {
    font-size: medium;
}

.largerDirections {
    font-size: x-large;
    font-weight: 700;
    font-family: 'Aboreto', system-ui;

}

.dogPic {
    max-width: 600px;
    max-height: 600px;
    margin: 5px
}


.invPic {
    padding: 30px
}

.invGroup {
    margin: 20px;
}

.endSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    background: #bd8d7d;
    margin-top: 20px;
    text-align: center;
    border-top: 5px solid black;
}

.endText {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.endTextQ {
    font-size: large;
    font-weight: 800;
    margin-bottom: 10px;
    font-family: 'Aboreto', system-ui;

}

.endTextA {
    font-size: small;
    font-weight: 400;
}

.textAndButton {
    max-width: 50%;
}

.endImage {
    max-width: 50%;
}

.endButton {
    margin-bottom: 10px;
}

@media only screen and (max-width:500px) {
    .endImage {
        background-position: 50%;
    }
}