.ncmsPic {
  max-width: 45%;
}

h2 {
  font-family: 'Aboreto', system-ui;

}

.wholePage1 {
  position: absolute;
    top: 0;
    max-width: 100%;
    background-position: 40%;
    background-color: #825344;
    padding-top: 100px;
}


.teachBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 30px;
  margin-bottom: 50px;
  background-color: #fcefd9;
  
}

.teachText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 45%;
  padding: 5%;
  font-size: smaller;
}


@media only screen and (max-width:1230px) {
  .teachBox {
      flex-wrap: wrap;
      margin-left: 5%;
      margin-right: 5%;
      text-align: center;
  }
  .ncmsPic {
    max-width: 100%;
  }

  .teachText {
    max-width: 90%;
    padding: 5%
  }
}

.largerDirections {
  font-size: x-large;
  font-weight: 700;
  text-align: center;
  align-items: center;
  font-family: 'Aboreto', system-ui;

}

.directions2 {
  background: #bd8d7d;
  padding: 15px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.inventory {
  background-image: url("../images/knotsBackground.jpg");
  background-size: cover;
  background-position: 0%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.vid {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 20px;
  width: 560px;
  height: 315px;
}


.scrollVids {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-top: 5px solid black;
  background: #fcefd9;
}

.scrollArea {
  height: 360px
}

@media only screen and (max-width:1230px) {
  .vid {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    width: 320px;
    height: 190px;
  }

  .scrollArea {
    height: 215px
  }
}

